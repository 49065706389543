import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MonthlySale from "./component/monthly_sale";
import PaymodeSale from "./component/paymodewiseSAle";
import ItemGroupWiseSale from "./component/groupSalereport";
import HourlySale from "./component/hourlySale";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
  setpaymodStatus,
} from "../../utility/Redux/refresh";
import FunctionBooking from "./component/functionBooking";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import TotalSaleComp from "./component/totalSale";
import SectionWiseSale from "./component/sectionWiseSale";
import { setloading } from "../../utility/Redux/modal";
import ProfileModal from "../../utility/component/modals/profile";
import DayTaxCollacted from "./component/subComponent/datTextCollacted";
import { Collapse } from "@mui/material";
import Deleted from "./component/deleted";
import WeeklySale from "./component/weekly";
import DiscountedBillList from "./component/discountedBill";
const Hotal_res_DashView = ({ prefix }) => {
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(true);
  const dispatch = useDispatch();
  const checkDateData = useSelector(SelectDate);
  var PaymodeStatus = useSelector(SelectPaymodeStatus);

  const ref = useRef(null);

  const RefreshFunction = () => {
    dispatch(setpaymodStatus(Number(PaymodeStatus) + Number(1)));
  };
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    ref.current = setInterval(RefreshFunction, 3 * 60 * 1000);

    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, []);

  useEffect(() => {
    dispatch(setloading(true));
  }, [checkDateData]);
  const navigate = useNavigate();
  return (
    <div className="pb-2 px-2 text-md  overflow-y-auto  flex-col h-full  w-full bg-white">
      <div className="-ml-2 -mr-2 pb-3 pt-3 t:pt-0 ">
        <div
          style={{ fontSize: 20 }}
          className="flex text-lg text-lime-700 font-bold mt-6 pl-2 items-center"
        >
          <div className="w-32 whitespace-nowrap  ">Total Sale :</div>{" "}
          <TotalSaleComp prefix={prefix} />{" "}
          {open ? (
            <ExpandLess onClick={handleClick} color="secondary" />
          ) : (
            <ExpandMore color="secondary" onClick={handleClick} />
          )}
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <DayTaxCollacted prefix={prefix} />
        </Collapse>
      </div>

      <PaymodeSale prefix={prefix} />
      <DiscountedBillList prefix={prefix} />
      <Deleted prefix={prefix} />

      <ItemGroupWiseSale prefix={prefix} />

      <SectionWiseSale prefix={prefix} />

      <HourlySale prefix={prefix} />

      <WeeklySale prefix={prefix} />
      <FunctionBooking prefix={prefix} />
      <MonthlySale prefix={prefix} />
      <ProfileModal prefix={prefix} />
    </div>
  );
};

export default Hotal_res_DashView;

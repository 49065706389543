import React, { useEffect, useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { selectPrefix } from "../../../utility/Redux/profile";
import { setloading } from "../../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";

import { selectheader } from "../../../utility/Redux/security";
const FunctionBooking = ({ prefix }) => {
  const [functionBooking, setfunctionBooking] = useState([]);
  const [month, setMonth] = useState([]);
  var from = moment(new Date()).subtract(12, "month").format("YYYY-MM-DD");
  var to = moment(new Date()).format("YYYY-MM-DD");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const ncSaleStatus = useSelector(SelectNcSaleStatus)

  const headers = useSelector(selectheader);

  const getMonthDetails = () => {
    dispatch(setloading(true));

    axios
      .post(
        `/api/salesReports/monthlyFunctionCounts`,
        {
          table_prefix: prefix,
          from_date: from,
          to_date: to,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            MONTH: data[index].MONTH_NAME,
            id: index + 1,
          });
        }
        setMonth(rowList);
        FunctionDetails();
        dispatch(setloading(false));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getMonthDetails();
  }, [prefix]);
  const FunctionDetails = () => {
    if (month[0]) {
      dispatch(setloading(true));
      axios
        .post(
          `/api/salesReports/functionBookings`,
          {
            table_prefix: prefix,
            from_date: from,
            to_date: to,
          },
          {
            headers: headers,
          }
        )
        .then((d) => {
          let rowList = [];
          let data = d.data;

          for (let index = 0; index < month.length; index++) {
            const functionsType = [];

            const filterList = data.filter((d, i) => {
              return d.MONTH_NANME === month[index].MONTH;
            });

            filterList.map((d, i) => {
              rowList.push({
                monthName: month[index].MONTH,
                function: [
                  {
                    functionsType: d.FUNCTION_TYPE,
                    totalFunction: d.TOTAL_FUNC,
                  },
                ],
              });
            });
          }

          setfunctionBooking(rowList);

          dispatch(setloading(false));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(setloading(false));
        });
    }
  };
  useEffect(() => {
    FunctionDetails();
  }, [month]);

  return (
    <>
      {month[0] ? (
        <div className="ml-1 pb-4   ">
          <div
            style={{ fontSize: 16 }}
            className="flex mb-1   text-lg text-[#2a3d66] font-bold"
          >
            Function Booking
          </div>
          <div className="grid pl-1 d:grid-cols-8 l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2  ">
            {month.map((d, index) => {
              const funcCount = functionBooking.filter((countData, i) => {
                return countData.monthName === d.MONTH;
              });
              return (
                <div
                  key={index}
                  className={`rounded-lg overflow-hidden hover:cursor-pointer bg-transparent `}
                  onClick={() => {
                    navigate("/function_bookings", {
                      state: {
                        month: d.MONTH,
                      },
                    });
                  }}
                >
                  <div className=" px-1 pt-1 pb-1   flex items-center w-16  rounded-tr-md rounded-tl-md bg-[#facbd3]  ">
                    <div className="w-full ">
                      <div
                        className=" text-black flex items-center   "
                        style={{ fontSize: 14 }}
                      >
                        <span className="text-[#37373b]  flex items-center pl-1 ">
                          {d.MONTH.substring(0, 3)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`pl-2 pr-2 pb-2  pt-1   bg-[#b1b1b5]  hover:cursor-pointer hover:shadow-lg hover:z-40 hover:bg-slate-200 ${
                      funcCount[0] ? "h-full" : "h-12"
                    }  `}
                  >
                    {funcCount.map((d, i) => {
                      return (
                        <div
                          className=" text-black flex "
                          style={{ fontSize: 14 }}
                        >
                          <span className="text-[#404042] ">
                            {d.function[0].functionsType}
                          </span>
                          <span className="text-[#404042] pl-1 ">
                            ({d.function[0].totalFunction})
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FunctionBooking;

import moment from "moment";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toProperCase } from "../utility/hooks/toProperCase";
import { FormatNumber2 } from "../utility/component/tools";
import NumberToWords3 from "../utility/hooks/numerToWords";
import { useSearchParams } from "react-router-dom";
import { generateHmac } from "../utility/function";

const ViewBillImage = () => {
  const [searchPeram] = useSearchParams(window.location.search);
  const p = searchPeram.get("prefix");
  const prefix = p + "_";
  const billInvoice = searchPeram.get("bill");
  const Counter = searchPeram.get("counter");
  const [list, setList] = useState([]);
  const [error, setError] = useState("");
  const [companyDetails, setCompanyDetails] = useState({
    address: "",
    city: "",
    company_name: "",
    companyWebsite: "",
    email: "",
    company_mobile: "",
    state: "",
  });

  const token = generateHmac(prefix);

  const CompanyInfo = () => {
    axios
      .post(
        `/api/users/companyInfo`,
        {
          company_email: "",
          table_prefix: prefix,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (response !== null) {
          const companyDetails = response.data[0];
          getViewBillDetatils(companyDetails.company_email);

          setCompanyDetails(companyDetails);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getViewBillDetatils = (email) => {
    const data = {
      table_prefix: prefix,
      INVNO: billInvoice,
      COUNTER_NO: Counter,
      company_email: email,
    };
    console.log(data);
    axios
      .post(
        "/api/foodBevBill/foodBill",

        data,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${generateHmac(email)}`,
          },
        }
      )

      .then((response) => {
        const dataList = response.data.response;
        const currentTime = new Date();
        const inputTime = dataList[0].ENT_TIME;
        const [hours, minutes] = inputTime.split(":");

        // if(moment(currentTime).format('YYYY-MM-DD') === moment(dataList[0].DATE).format('YYYY-MM-DD')){
        const targetTime = new Date(
          currentTime.getFullYear(),
          currentTime.getMonth(),
          currentTime.getDate(),
          parseInt(hours),
          parseInt(minutes),
          0
        );
        const oneHourAgo = new Date(currentTime);
        console.log(targetTime);
        oneHourAgo.setHours(oneHourAgo.getHours() - 1);
        console.log(oneHourAgo);
        console.log(dataList[0]);

        // if (targetTime < oneHourAgo) {
        //   setError("It appears that the bill link has expired.");
        // } else {
        setList(dataList);
        // }
        // }else{
        //   setError('It appears that the bill link has expired.');
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (prefix && Counter && billInvoice) {
      CompanyInfo();
    }
  }, [prefix, Counter]);

  const totalSerAmt = list.reduce(
    (acc, row) => acc + Number(Number(row.SC_AMT)),
    0
  );
  const totalDiscount = list.reduce(
    (acc, row) => acc + Number(Number(row.DISQ_AMT)),
    0
  );

  return (
    <div className="w-full flex justify-center overflow-auto">
      {list[0] ? (
        <div className="h-full w-[320px]  bg-white print-component      ">
          <div
            style={{
              border: "1px solid #b5b3b3",
            }}
            className="w-full h-full "
          >
            <div className="w-full flex  text-[17px] justify-center font-semibold">
              {"Hotel"}
            </div>
            <div className="w-full flex  text-[15px] justify-center font-semibold">
              {companyDetails.company_name}
            </div>

            <div className="flex justify-center">
              <div
                style={{ fontSize: 12 }}
                className="w-full font-semibold flex justify-center max-w-[260px]  text-center "
              >
                {companyDetails.address}, {companyDetails.state},
                {companyDetails.city},
              </div>
            </div>

            <div className="flex justify-center">
              <div
                style={{ fontSize: 12 }}
                className="w-full font-semibold flex justify-center max-w-[260px]  text-center "
              >
                Ph.: {companyDetails.company_mobile},
              </div>
            </div>

            <div className="flex justify-center">
              <div
                style={{ fontSize: 12 }}
                className="w-full font-semibold flex justify-center max-w-[260px]  text-center "
              >
                GST NO. {companyDetails.regd_no},
              </div>
            </div>

            <div
              style={{ fontSize: 12 }}
              className="w-full flex justify-center "
            ></div>
            <div style={{ fontSize: 12 }} className="flex">
              {" "}
              <div
                style={{ fontSize: 12 }}
                className="w-full flex justify-center pl-2"
              >
                {list[0]?.ADDRESS}
              </div>{" "}
            </div>

            <div
              style={{ borderTopWidth: "1px" }}
              className="w-full    border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-dotted border-[#b5b3b3] "
            ></div>
            <div
              style={{ fontSize: 12 }}
              className="w-full text-sm mt-2  font-bold flex items-center justify-center"
            >
              {" "}
              <div>TAX INVOICE</div>
            </div>
            <div
              style={{ fontSize: 16 }}
              className="w-full text-sm  mb-2 font-bold flex items-center justify-center"
            >
              {" "}
              <div>HOME DELIVERY</div>
            </div>
            <div className="w-full flex justify-between">
              <div className="w-full">
                <div
                  style={{ borderTopWidth: "1px" }}
                  className="w-full    border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-dotted border-[#b5b3b3] "
                ></div>
                <div
                  style={{ fontSize: 12 }}
                  className="w-full font-bold px-1 flex justify-between"
                >
                  <div className="">Bill No. : {billInvoice}</div>
                  <div style={{ fontSize: 12 }}>
                    {" "}
                    {`Date: ${moment.utc(list[0]?.DATE).format("DD/MM/yyyy")}`}
                  </div>
                </div>
                <div className="flex justify-between w-full ">
                  <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
                    Counter : {list[0]?.COUNTER_NAME}
                  </div>{" "}
                  <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
                    Kot No : {list[0]?.KOT_NO}
                  </div>
                </div>

                <div style={{ fontSize: 12 }} className={"mt-1 px-1"}>
                  Waiter Name : {list[0]?.WNAME}
                </div>
              </div>
            </div>

            <table className="w-full">
              <thead
                style={{ borderTopWidth: "1px", borderBottomWidth: "1px" }}
                className="w-full  border-black h-6"
              >
                <tr className="w-full   ">
                  <th
                    style={{
                      fontSize: 12,
                      borderRightWidth: "1px",
                      width: "47%",
                    }}
                    className=" whitespace-nowrap  border-black "
                  >
                    <div className="w-full flex justify-center">Item Name</div>
                  </th>
                  <th
                    style={{
                      fontSize: 12,
                      borderRightWidth: "1px",
                      width: "18%",
                    }}
                    className="   border-black"
                  >
                    <div className="w-full flex justify-center"> Rate</div>
                  </th>
                  <th
                    style={{
                      fontSize: 12,
                      borderRightWidth: "1px",
                      width: "13%",
                    }}
                    className="  border-black "
                  >
                    <div className="w-full flex justify-center"> Qnty</div>
                  </th>

                  <th style={{ fontSize: 12, width: "22%" }}>
                    <div className="w-full flex justify-center"> Amount</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => {
                  return (
                    <tr key={index} style={{}}>
                      <td
                        className=" border-black border-dotted text-start "
                        style={{ fontSize: 12, borderRightWidth: "1px" }}
                      >
                        <div className="pl-1">
                          {toProperCase(item.ITEMNAME)}
                        </div>

                        <div className="text-white h-[2px]"> </div>
                      </td>
                      <td
                        className=" pr-1  border-dotted border-black text-right "
                        style={{ fontSize: 12, borderRightWidth: "1px" }}
                      >
                        {FormatNumber2(item.BASIC_RATE)}
                      </td>
                      <td
                        className=" pr-2 border-dotted text-right  border-black "
                        style={{ fontSize: 12, borderRightWidth: "1px" }}
                      >
                        {item.QNTY > 0 && item.QNTY}
                      </td>

                      <td className="text-right pr-2 " style={{ fontSize: 12 }}>
                        {FormatNumber2(item.AMOUNT)}
                      </td>
                    </tr>
                  );
                })}

                <tr
                  style={{ borderTopWidth: "1px" }}
                  className=" border-black "
                >
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2"
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    Total :
                  </td>
                  <td
                    className="pr-2 font-semibold border-black"
                    style={{ fontSize: 12, textAlign: "right" }}
                  >
                    {list[0] &&
                      FormatNumber2(
                        list.reduce(
                          (total, row) =>
                            Number(total) +
                            Number(Number(row.AMOUNT) + Number(row.DISQ_AMT)),
                          0
                        )
                      )}
                  </td>
                </tr>

                <tr
                  style={{ borderTopWidth: "1px" }}
                  className=" border-black "
                >
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2"
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    Discount :
                  </td>
                  <td
                    className="pr-2 font-semibold border-black"
                    style={{ fontSize: 12, textAlign: "right" }}
                  >
                    {FormatNumber2(totalDiscount)}
                  </td>
                </tr>

                <tr className=" border-black ">
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2"
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    Central GST {list[0]?.CGST_RATE}% :
                  </td>
                  <td
                    className="pr-2 font-semibold border-black"
                    style={{ fontSize: 12, textAlign: "right" }}
                  >
                    {list[0] &&
                      FormatNumber2(
                        list.reduce(
                          (total, row) =>
                            Number(total) + Number(Number(row.CGST_AMT)),
                          0
                        )
                      )}
                  </td>
                </tr>

                <tr className=" border-black ">
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2"
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    State GST {list[0]?.SGST_RATE}% :
                  </td>
                  <td
                    className="pr-2 font-semibold border-black"
                    style={{ fontSize: 12, textAlign: "right" }}
                  >
                    {list[0] &&
                      FormatNumber2(
                        list.reduce(
                          (total, row) =>
                            Number(total) + Number(Number(row.SGST_AMT)),
                          0
                        )
                      )}
                  </td>
                </tr>
                <tr className=" border-black ">
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2"
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    Service Charge :
                  </td>
                  <td
                    className="pr-2 font-semibold border-black"
                    style={{ fontSize: 12, textAlign: "right" }}
                  >
                    {FormatNumber2(totalSerAmt)}
                  </td>
                </tr>
                <tr className=" border-black ">
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2"
                    style={{
                      textAlign: "right",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    Round Off :
                  </td>
                  <td
                    className="pr-2 font-semibold border-black"
                    style={{ fontSize: 12, textAlign: "right" }}
                  >
                    {list[0]?.ROUND_OFF}
                  </td>
                </tr>
                <tr
                  style={{ borderTopWidth: "1px", borderBottomWidth: "1px" }}
                  className=" border-black "
                >
                  <td
                    colSpan="3"
                    className=" border-black font-semibold border-dotted pr-2 "
                    style={{
                      textAlign: "left",
                      fontSize: 11,
                      borderRightWidth: "1px",
                    }}
                  >
                    <div className="flex justify-between items-center pl-1">
                      {list[0]
                        ? `${moment.utc(list[0].DATE).format("hh:mm A")}`
                        : null}
                      <div className="text-[13px] font-semibold ">
                        PLEASE PAY
                      </div>
                    </div>
                  </td>
                  <td
                    className="pr-2 font-semibold border-black text-[15px]"
                    style={{ textAlign: "right" }}
                  >
                    {FormatNumber2(list[0]?.GTOTAL)}
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              style={{
                fontSize: 12,
              }}
              className={
                "w-full flex justify-center py-1 items-center text-center"
              }
            >
              {toProperCase(NumberToWords3(list[0]?.GTOTAL))}
            </div>
            <div
              style={{ borderTopWidth: "1px" }}
              className="w-full   border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-1 border-black "
            ></div>
            <div
              style={{
                fontSize: 12,
              }}
              className={
                "w-full flex font-semibold justify-center items-center "
              }
            >
              Amount Received
            </div>

            <div className="flex w-full justify-center items-center ">
              {list[0]?.CASH_AMT ? (
                <div
                  style={{
                    fontSize: 12,
                  }}
                  className={" flex justify-center"}
                >
                  {" "}
                  Cash Amount : {FormatNumber2(list[0]?.CASH_AMT)}
                </div>
              ) : null}

              {list[0]?.CARD_AMOUNT ? (
                <div
                  style={{
                    fontSize: 12,
                  }}
                  className={" flex justify-center"}
                >
                  {list[0]?.CASH_AMT ? <div className="px-2"> | </div> : null}
                  Card Amount :
                  {FormatNumber2(list[0]?.CARD_AMOUNT) +
                    " " +
                    `[${list[0]?.CARD_NO}]`}
                </div>
              ) : null}
            </div>

            <div className="w-full flex justify-center items-center">
              {list[0]?.BTC_AMT ? (
                <div
                  style={{
                    fontSize: 12,
                  }}
                  className={" flex justify-center"}
                >
                  {" "}
                  BTC Amount : {FormatNumber2(list[0]?.BTC_AMT)}
                </div>
              ) : null}
              {list[0]?.BTC_AMT && list[0]?.CHQ_AMT ? (
                <div className="px-2">|</div>
              ) : null}

              {list[0]?.CHQ_AMT ? (
                <div
                  style={{
                    fontSize: 12,
                  }}
                  className={" flex justify-center"}
                >
                  Cheque Amount : {FormatNumber2(list[0]?.CHQ_AMT)}{" "}
                </div>
              ) : null}
            </div>

            <div className="w-full flex justify-center items-center ">
              {list[0]?.COUPAN_AMOUNT ? (
                <div
                  style={{
                    fontSize: 12,
                  }}
                  className={" flex justify-center"}
                >
                  {" "}
                  Coupon Amount : {FormatNumber2(list[0]?.COUPAN_AMOUNT)}{" "}
                </div>
              ) : null}
            </div>

            <div
              style={{ borderTopWidth: "1px" }}
              className="w-full  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0  mt-2 border-black "
            ></div>

            <div
              style={{
                fontSize: 11,
              }}
              className={
                " flex text-center  justify-center mt-1 text-[#4e4a4a]"
              }
            >
              All Disputes Subjected to Jalandhar Jurisdiction.
            </div>

            <div
              style={{
                fontSize: 11,
              }}
              className={" flex justify-center text-[#4e4a4a]"}
            >
              Powered by geniusoffice .
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {error && <div className=" flex items-center h-screen">{error} </div>}
    </div>
  );
};

export default ViewBillImage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
} from "../../../utility/Redux/refresh";
import { setloading } from "../../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../utility/component/card";
import { selectheader } from "../../../utility/Redux/security";
import { FormatNumber2 } from "../../../utility/component/tools";
const DiscountedBills = ({ prefix }) => {
  const dispatch = useDispatch();
  const [discountedBill, setdiscountedBill] = useState([]);
  const PaymodeStatus = useSelector(SelectPaymodeStatus);
  const navigate = useNavigate();
  const checkDateData = useSelector(SelectDate);

  const headers = useSelector(selectheader);

  const getList = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/discountedBills`,
        {
          table_prefix: prefix,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;
        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }

        setdiscountedBill(rowList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getList();
  }, [checkDateData, PaymodeStatus, prefix]);
  return (
    <div>
      {discountedBill[0] ? (
        <div className="pb-4 ">
          <div
            style={{ fontSize: 16 }}
            className="  pl-1 mb-1 text-brown text-lg flex font-bold"
          >
            Discounted bills
          </div>
          <div className="grid grid-cols-1 t:grid-cols-2 l:grid-cols-3 2xl:grid-cols-4 gap-2  pl-2 ">
            {discountedBill[0]
              ? discountedBill.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className=" rounded-lg overflow-hidden flex d:flex bg-gray-200 p-2 "
                    >
                      <div className=" ">
                        <div className="flex">
                          <div className="w-[100px]">Customer</div> :
                          <div className="ml-1">
                            {d.NAME} {d.MOBILE ? `- [ ${d.MOBILE} ]` : ""}
                          </div>
                        </div>
                        <div className=" ">
                          <div className="flex">
                            <div className="w-[100px]">Amount</div> :
                            <div className="ml-1">
                              {FormatNumber2(d.BASIC_AMT)}
                            </div>
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-[100px]">Service Charge</div> :
                          <div className="ml-1">{FormatNumber2(d.SC_AMT)}</div>
                        </div>

                        <div className="flex">
                          <div className="w-[100px]">Tax Amt.</div> :
                          <div className="ml-1">
                            {FormatNumber2(d.TAX_AMT + d.SRV_AMT)}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-[100px]">Discount</div> :
                          <div className="ml-1">
                            {FormatNumber2(d.DISQ_AMT)}
                          </div>
                        </div>

                        <div className=" ">
                          <div className="flex">
                            <div className="w-[100px]">Total Amt.</div> :
                            <div className="ml-1">
                              {FormatNumber2(d.GTOTAL)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DiscountedBills;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { HandleIndexedDB } from "../../utility/indexDb";
import Hotal_res_DashView from "./dashboard";

import { ImCheckboxChecked } from "react-icons/im";
import { ImCheckboxUnchecked } from "react-icons/im";

function HotalResDashboard() {
  const [tabScreen, setTabScreen] = useState(0);

  //
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const databaseName = "userDetails";
    const storeName = "data";

    HandleIndexedDB({
      databaseName,
      storeName,
      callback: (dat) => {
        if (dat[0]) {
          const extractedData = [];
          const data = dat[0];
          // Iterate over the possible prefixes and company names
          for (let i = 1; i <= 5; i++) {
            // Determine the table prefix and company name keys
            const tablePrefixKey =
              i === 1 ? "table_prefix" : `table_prefix_${i}`;
            const companyNameKey =
              i === 1 ? "company_name" : `company_name_${i}`;

            // Push the extracted values into the array
            extractedData.push({
              table_prefix: data[tablePrefixKey],
              company_name: data[companyNameKey],
            });
          }
          setUserData(
            extractedData.filter(
              (o) => o.table_prefix !== null && o.table_prefix !== ""
            )
          );
        }
      },
    });
  }, []);
  return (
    <div className="pb-4 pt-12 px-1">
      <div className="h-full flex flex-col  ">
        <div className=" w-full  rounded-sm flex ">
          {userData.map((user, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setTabScreen(index);
                }}
                style={{
                  color: tabScreen === index ? "white" : "gray",
                }}
                className={`flex-1 p-2 ${
                  tabScreen === index
                    ? "bg-[#2e6bdd]"
                    : " hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center    overflow-hidden ${
                  index === userData.length - 1
                    ? "rounded-r-md"
                    : index === 0
                    ? "rounded-l-md"
                    : ""
                }`}
              >
                {tabScreen === index ? (
                  <ImCheckboxChecked
                    fill={"white"}
                    size={window.innerWidth < 500 ? 14 : 18}
                    style={{
                      marginRight: "8px",
                    }}
                  />
                ) : (
                  <ImCheckboxUnchecked
                    fill={"gray"}
                    size={window.innerWidth < 500 ? 14 : 18}
                    style={{
                      marginRight: "8px",
                    }}
                  />
                )}

                <div
                  style={{
                    fontSize: window.innerWidth < 500 ? "10px" : "16px",
                    fontWeight: tabScreen === index ? 600 : 400,
                  }}
                  className=" h-full flex items-center  "
                >
                  {user.company_name}
                </div>
              </div>
            );
          })}
        </div>
        {console.log(userData[tabScreen]?.table_prefix)}
        <div className=" flex flex-col overflow-y-auto overflow-x-hidden h-full ">
          <div className="h-full">
            {<Hotal_res_DashView prefix={userData[tabScreen]?.table_prefix} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HotalResDashboard;

import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
} from "../../../utility/Redux/refresh";
import { selectPrefix } from "../../../utility/Redux/profile";
import { FormatNumber2 } from "../../../utility/component/tools";
import { setloading } from "../../../utility/Redux/modal";

import { selectheader, selectToken } from "../../../utility/Redux/security";

const TotalSaleComp = ({ prefix }) => {
  const [totalSaleData, settotalSaleData] = useState([]);
  const PaymodeStatus = useSelector(SelectPaymodeStatus);
  const token = useSelector(selectToken);
  const checkDateData = useSelector(SelectDate);
  const authHeader = useSelector(selectheader);
  const dispatch = useDispatch();

  const getTotalSalereport = useCallback(() => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/dayTotalSale`,
        {
          table_prefix: prefix,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: authHeader,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }

        settotalSaleData(rowList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  });

  useEffect(() => {
    getTotalSalereport();
  }, [checkDateData, PaymodeStatus, prefix]);

  return (
    <div className="ml-2">
      {totalSaleData[0]
        ? `₹${FormatNumber2(totalSaleData[0].NET_AMT)}`
        : "0.00"}
    </div>
  );
};

export default TotalSaleComp;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
  setSectionStatus,
} from "../../../utility/Redux/refresh";
import { setloading } from "../../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../utility/component/card";
import { selectheader } from "../../../utility/Redux/security";
const ItemGroupWiseSale = ({ prefix }) => {
  const dispatch = useDispatch();
  const [itemReport, setItemReport] = useState([]);
  const PaymodeStatus = useSelector(SelectPaymodeStatus);
  const navigate = useNavigate();
  const checkDateData = useSelector(SelectDate);

  const headers = useSelector(selectheader);

  const getItemSaleReport = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/groupSaleReport`,
        {
          table_prefix: prefix,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          if (data[index].NET_AMT > 0) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }
        }

        setItemReport(rowList);
        dispatch(setSectionStatus());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getItemSaleReport();
  }, [checkDateData, PaymodeStatus, prefix]);
  console.log(itemReport);
  return (
    <div>
      {itemReport[0] ? (
        <div className="pb-4 ">
          <div
            style={{ fontSize: 16 }}
            className="flex  pl-1 mb-1 text-brown text-lg flex font-bold"
          >
            Group Wise Sale
          </div>
          <div className="grid d:grid-cols-8 l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2  pl-2 ">
            {itemReport[0]
              ? itemReport.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className=" rounded-lg overflow-hidden flex d:flex  "
                      onClick={() => {
                        navigate("/item_group", {
                          state: {
                            table_prefix: prefix,
                            from_date: checkDateData,
                            to_date: checkDateData,
                            itm_group: d.ITM_GROUP,
                          },
                        });
                      }}
                    >
                      <Card t={d.ITM_GROUP} amount={d.NET_AMT} />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ItemGroupWiseSale;

import React, { useEffect } from "react";
import Auth from "./auth/auth";
import { Login } from "./auth/Login";
import ProtectedRoute from "./auth/util/protectedRoute";
import NotFound from "./auth/notFound";
import Home from "./sales-Desk/home";
import MonthlySale from "./sales-Desk/Dashboard/component/monthly_sale";
import RestaurantSale from "./sales-Desk/reports/restaurant_Sale";
import FastFood from "./sales-Desk/Dashboard/component/fastFood";
import ItemGroupsale from "./sales-Desk/reports/itemGroup_sale";
import { CompnySignup } from "./auth/signup";
import ActivationAccount from "./auth/activation";
import { ForgotPassword } from "./auth/changePassword";
import { ResetEmail } from "./auth/resetUserEmail";
import VerifyEmail from "./auth/verifyEmail";
import WiseReport from "./sales-Desk/Dashboard/component/subComponent/dateWiseSale";
import Running from "./sales-Desk/Dashboard/component/subComponent/running";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import SaleDeskRooms from "./sales-Desk/rooms/rooms";
import FunctionSubBooking from "./sales-Desk/Dashboard/component/subComponent/functionBookings";
import ViewBillImage from "./invoice";
const AppRoutes = () => {
  const authRoutes = [
    {
      path: "login",
      element: <Login />,
    },

    {
      path: "signup",
      element: <CompnySignup />,
    },

    {
      path: "reset_password_email",
      element: <ResetEmail />,
    },
  ];
  const privateRoutes = [
    { path: "/", element: Home, userType: "allowed" },
    { path: "/home", element: Home, userType: "allowed" },
    { path: "/monthly_sale", element: MonthlySale, userType: "allowed" },
    { path: "/restaurant_sale", element: RestaurantSale, userType: "allowed" },
    { path: "/sale_desk_rooms", element: SaleDeskRooms, userType: "allowed" },

    { path: "/fast_food_sale", element: FastFood, userType: "ADMIN" },
    { path: "/item_group", element: ItemGroupsale, userType: "allowed" },
    { path: "/running", element: Running, userType: "ADMIN" },
    { path: "/date_wise_sale", element: WiseReport, userType: "allowed" },
    {
      path: "/function_bookings",
      element: FunctionSubBooking,
      userType: "MANAGER ADMIN",
    },
  ];

  const otherRouter = [
    "/changeUserPassword",
    "/activateUser",
    "/emailVerified",
    "invoice",
    "/auth/mobile_signup",
    "/auth/barCode",
    "/auth/demoRegister",
  ];

  const expires = localStorage.getItem("expiresAt");
  const date = new Date().getTime();
  const navigate = useNavigate();
  const location = useLocation();

  const UserTime = () => {
    if (expires) {
      if (expires < date) {
        navigate("/auth/login");
      }
    } else {
      navigate("/auth/login");
    }
  };
  console.log(location.pathname);
  useEffect(() => {
    const isOtherRoute = otherRouter.filter(
      (r) => r === location.pathname.replace("/", "")
    );
    if (!isOtherRoute[0]) {
      UserTime();
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/auth" element={<Auth />}>
          {authRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute>
                    {" "}
                    <route.element />
                  </ProtectedRoute>
                }
              />
            );
          })}
        </Route>
        <Route path="/changeUserPassword" element={<ForgotPassword />} />
        <Route path="/activateUser" element={<ActivationAccount />} />
        <Route path="/emailVerified" element={<VerifyEmail />} />
        <Route path="/invoice" element={<ViewBillImage />} />
      </Routes>
    </>
  );
};

export default AppRoutes;

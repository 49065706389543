import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectDate } from "../../utility/Redux/refresh";
import axios from "axios";
import { selectheader } from "../../utility/Redux/security";
import { selectPrefix } from "../../utility/Redux/profile";
import { useState } from "react";
import {
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import { useEffect } from "react";
import moment from "moment";
import { FormatNumber2 } from "../../utility/component/tools";

const SaleDeskRooms = () => {
  const dispatch = useDispatch();
  const [dayTotalSale, setDayTotalSale] = useState([]);
  const [dayTaxCollected, setDayTaxCollected] = useState([]);
  const [weeklySaleReport, setWeeklySaleReport] = useState([]);
  const [monthlySaleReport, setmonthlySaleReport] = useState([]);
  const [expectedArrivals, setexpectedArrivals] = useState([]);
  const [expectedDeparture, setexpectedDeparture] = useState([]);
  const [todayCheckOut, settodayCheckOut] = useState([]);
  const [stayingGuest, setstayingGuest] = useState([]);
  const [totalRooms, settotalRooms] = useState([]);
  const [reservation, setReservation] = useState([]);
  const checkCurrenDate = useSelector(SelectDate);
  const headers = useSelector(selectheader);
  const prefix = useSelector(selectPrefix);

  const getDayTotalSale = () => {
    dispatch(setSpinnerLoading("Loading "));
    axios
      .post(
        `/api/salesReportsRooms/dayTotalSale`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setDayTotalSale(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getDayTaxCollected();
      });
  };

  const getDayTaxCollected = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/dayTaxCollected`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setDayTaxCollected(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getExpectedArrivals();
      });
  };

  const getExpectedArrivals = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/expectedArrivals`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setexpectedArrivals(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getExpectedDeparture();
      });
  };

  const getExpectedDeparture = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/expectedDeparture`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setexpectedDeparture(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getTodayCheckOut();
      });
  };

  const getTodayCheckOut = () => {
    dispatch(setSpinnerLoading("Loading"));

    axios
      .post(
        `/api/salesReportsRooms/todayCheckOut`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        settodayCheckOut(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getStayingGuest();
      });
  };

  const getStayingGuest = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/stayingGuest`,
        {
          table_prefix: prefix,
          date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data.results;
        setstayingGuest(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getTotalRooms();
      });
  };

  const getTotalRooms = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/totalRooms`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data.results;
        settotalRooms(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getWeeklySaleReport();
      });
  };

  const getWeeklySaleReport = () => {
    dispatch(setSpinnerLoading("Loading"));

    axios
      .post(
        `/api/salesReportsRooms/weeklySaleReport`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate)
            .startOf("week")
            .format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).endOf("week").format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data.results;
        setWeeklySaleReport(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getMonthlySaleReport();
      });
  };

  const getMonthlySaleReport = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/monthlySaleReport`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data.results;
        setmonthlySaleReport(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getReservation();
      });
  };

  const getReservation = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/salesReportsRooms/reservations`,
        {
          table_prefix: prefix,
          fdate: moment(checkCurrenDate).format("YYYY-MM-DD"),
          tdate: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data.results;
        setReservation(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getDayTotalSale();
  }, [checkCurrenDate]);

  return (
    <div
      id="loader"
      className="pt-[45px] px-[12px] pb-3  text-md bg-white h-full overflow-auto"
    >
      {dayTotalSale[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Day Total Sale
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {dayTotalSale[0]
              ? dayTotalSale.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.RCP_PAY_MODE}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹ {FormatNumber2(d.NET_AMT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {dayTaxCollected[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Day Tax Collected
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {dayTaxCollected[0]
              ? dayTaxCollected.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.TAX_HEAD}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹{FormatNumber2(d.SGST_AMT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
      {reservation[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6 rounded-md"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Reservations
            </div>
          </div>

          <div className="grid md:grid-cols-3 d:grid-cols-4  ll:grid-cols-5  gap-2 pt-2">
            {reservation[0]
              ? reservation.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex  border rounded-md  hover:cursor-pointer hover:z-40"
                    >
                      {d.PAY_MODE === "CANCEL" ? (
                        <div className=" p-2 w-full rounded-md  text-md">
                          <div className="  flex justify-between items-center ">
                            <div className="grow ">
                              <div className="text-[#4a4a4a] font-bold text-lg flex grow ">
                                Room No.:
                                {d.ROOM_NO ? (
                                  <div className="text-md  flex items-center ml-1 xl:pt-1 ll:pt-0">
                                    {d.ROOM_NO}
                                    <span>Cancel</span>
                                  </div>
                                ) : (
                                  <div className="text-md  flex items-center ml-1 xl:pt-1 ll:pt-0">
                                    Cancelled
                                  </div>
                                )}
                              </div>

                              <div className="text-[#4a4a4a] font-medium  flex grow mt-1">
                                Room Type:{" "}
                                <div className="uppercase ml-1">
                                  {d.ROOM_TYPE}{" "}
                                </div>
                              </div>

                              <div className="text-[#4a4a4a] font-medium  flex grow mt-1">
                                Name:{" "}
                                <div className="uppercase ml-1">
                                  {d.NAME}[{d.MOBILE}]
                                </div>
                              </div>

                              {/* <div className="text-[#4a4a4a] font-medium flex grow mt-1">
																Mobile:{" "}
																{
																	d.MOBILE
																}
															</div> */}

                              <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
                                Stay :
                                <div className="ml-1">
                                  {moment(d.ARV_DATE).format("DD-MM-YYYY")}
                                </div>{" "}
                                &nbsp;
                                <div>To</div>
                                <div className="ml-1">
                                  {moment(d.DEP_DATE).format("DD-MM-YYYY")}
                                </div>
                              </div>

                              {/* <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
																	Departure
																	Date:
																	<div className="ml-1">
																		{moment(
																			d.DEP_DATE
																		).format(
																			"YYYY-MM-DD"
																		)}
																	</div>
																</div> */}

                              <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
                                Remarks:
                                <div className="ml-1">{d.REMARKS}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" p-2 w-full rounded-md bg-[#E8E2DB]">
                          <div className="  flex justify-between items-center ">
                            <div className="grow ">
                              <div className="text-[#4a4a4a] font-bold text-lg flex grow ">
                                Room No.:
                                {d.ROOM_NO ? (
                                  d.ROOM_NO
                                ) : (
                                  <div className="text-md  flex items-center ml-1 xl:pt-1 ll:pt-0">
                                    No Room Blocked
                                  </div>
                                )}
                              </div>

                              <div className="text-[#4a4a4a] font-medium  flex grow mt-1">
                                Room Type:{" "}
                                <div className="uppercase ml-1">
                                  {d.ROOM_TYPE}{" "}
                                </div>
                              </div>

                              <div className="text-[#4a4a4a] font-medium  flex grow mt-1">
                                Name:{" "}
                                <div className="uppercase ml-1">
                                  {d.NAME}[{d.MOBILE}]
                                </div>
                              </div>

                              {/* <div className="text-[#4a4a4a] font-medium flex grow mt-1">
																Mobile:{" "}
																{
																	d.MOBILE
																}
															</div> */}

                              <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
                                Stay :
                                <div className="ml-1">
                                  {moment(d.ARV_DATE).format("DD-MM-YYYY")}
                                </div>{" "}
                                &nbsp;
                                <div>To</div>
                                <div className="ml-1">
                                  {moment(d.DEP_DATE).format("DD-MM-YYYY")}
                                </div>
                              </div>

                              {/* <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
																	Departure
																	Date:
																	<div className="ml-1">
																		{moment(
																			d.DEP_DATE
																		).format(
																			"YYYY-MM-DD"
																		)}
																	</div>
																</div> */}

                              <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
                                Amount Due:
                                <div className="ml-1">
                                  ₹{FormatNumber2(d.AMOUNT_DUE)}
                                  /-
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
      {stayingGuest[0] ? (
        <div className="w-full">
          <div
            style={{
              fontSize: 17,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Room Status
            </div>
          </div>

          <div className="grid md:grid-cols-3 d:grid-cols-4  ll:grid-cols-5  gap-2 pt-2">
            {stayingGuest[0]
              ? stayingGuest.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={
                        d.NAME && d.ROOM_STATUS === "Working"
                          ? {
                              boxShadow: "1px 1px 1px gray",
                              backgroundColor: "#E8E2DB",
                            }
                          : d.ROOM_STATUS === "Block"
                          ? {
                              boxShadow: "1px 1px 1px gray",
                              backgroundColor: "#f5e0bf",
                            }
                          : d.ROOM_STATUS === "Dirty"
                          ? {
                              boxShadow: "1px 1px 1px gray",
                              backgroundColor: "#fce881",
                            }
                          : d.ROOM_STATUS === "Management Block"
                          ? {
                              boxShadow: "1px 1px 1px gray",
                              backgroundColor: "#fadcdd",
                            }
                          : d.ROOM_STATUS === "Under Maintenance"
                          ? {
                              boxShadow: "1px 1px 1px gray",
                              backgroundColor: "#dae0e0",
                            }
                          : {
                              boxShadow: "1px 1px 1px gray",
                              backgroundColor: "#f2fad4",
                            }
                      }
                      className="flex d:flex  border rounded-md  hover:cursor-pointer hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            {d.ROOM_STATUS === "Dirty" ? (
                              <div className="flex w-full">
                                <div className="text-[#4a4a4a] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg flex   ">
                                  {d.ROOM_NO}
                                </div>
                                <div className="w-4/5 text-llg flex items-center ml-2">
                                  {d.ROOM_STATUS}
                                </div>
                              </div>
                            ) : d.ROOM_STATUS === "Management Block" ? (
                              <div className="flex w-full">
                                <div className="text-[#4a4a4a] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg flex   ">
                                  {d.ROOM_NO}
                                </div>{" "}
                                <div className="w-4/5 text-llg flex items-center ml-2">
                                  {d.ROOM_STATUS}
                                </div>
                              </div>
                            ) : d.ROOM_STATUS === "Block" ? (
                              <div className="flex w-full">
                                <div className="text-[#4a4a4a] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg flex   ">
                                  {d.ROOM_NO}
                                </div>{" "}
                                <div className="w-4/5 text-llg flex items-center ml-2">
                                  {d.ROOM_STATUS}
                                </div>
                              </div>
                            ) : d.ROOM_STATUS === "Under Maintenance" ? (
                              <div className="flex w-full">
                                <div className="text-[#4a4a4a] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg flex   ">
                                  {d.ROOM_NO}
                                </div>{" "}
                                <div className="w-4/5 text-llg flex items-center ml-2">
                                  {d.ROOM_STATUS}
                                </div>
                              </div>
                            ) : d.INVNO === null ? (
                              <div className="flex w-full">
                                <div className="text-[#4a4a4a] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg flex   ">
                                  {d.ROOM_NO}
                                </div>{" "}
                                <div className="w-4/5 text-md text-black flex items-center ml-2 justify">
                                  Vacant
                                </div>
                              </div>
                            ) : (
                              <div className="flex w-full">
                                <div className="text-[#4a4a4a] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg flex   ">
                                  {d.ROOM_NO}
                                </div>{" "}
                                <div className="w-4/5 text-md text-black flex items-center ml-2 justify">
                                  Occupied
                                </div>
                              </div>
                            )}

                            {d.NAME ? (
                              <div className="text-[#4a4a4a] font-medium  flex  mt-1">
                                <div className="uppercase ">
                                  {d.NAME}
                                  &nbsp;[
                                  {d.MOBILE}]
                                </div>
                              </div>
                            ) : null}

                            {d.NAME ? (
                              <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
                                Stay :
                                <div className="ml-1">
                                  {moment(d.ARV_DATE).format("DD-MM-YYYY")}
                                </div>{" "}
                                &nbsp;
                                <div>To</div>
                                <div className="ml-1">
                                  {moment(d.DEP_DATE).format("DD-MM-YYYY")}
                                </div>
                              </div>
                            ) : null}

                            {d.NAME ? (
                              <div className="text-[#4a4a4a] font-bold justify-end text-md flex grow mt-1">
                                Amount Due:
                                <div className="ml-1">
                                  ₹{FormatNumber2(d.AMOUNT_DUE)}
                                  /-
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {todayCheckOut[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1  text-[#d35843] rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Today Checkout
            </div>
          </div>

          <div className="grid md:grid-cols-3 d:grid-cols-4  ll:grid-cols-5  gap-2 pt-2">
            {todayCheckOut[0]
              ? todayCheckOut.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="flex items-center">
                              <div className="text-black  bg-[#cab59e] w-fit px-3 border border-solid border-black rounded-md justify-center  font-bold text-llg ">
                                {d.ROOM_NO}
                              </div>
                              <div className="text-[#d35843] ml-3">
                                {" "}
                                Checkout
                              </div>
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow mt-1">
                              Bill No.: {d.INVNO} &nbsp;
                              <div>
                                {" "}
                                @ Rs.:
                                <span className="ml-1">
                                  {FormatNumber2(d.NET_AMT)}
                                </span>
                              </div>
                            </div>
                            <div className="text-[#4a4a4a] font-medium  flex grow mt-1">
                              Guest Name:{" "}
                              <div className="uppercase ml-1">{d.NAME} </div>
                            </div>
                            <div className="text-[#4a4a4a] font-medium text-md flex grow mt-1">
                              No. of Nights:
                              <div className="ml-1">
                                {moment(d.DEP_DATE).diff(
                                  moment(d.ARV_DATE),
                                  "days"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {weeklySaleReport[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Weekly Sale
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {weeklySaleReport[0]
              ? weeklySaleReport.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.DAY_NAME}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹ {FormatNumber2(d.NET_AMT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      {monthlySaleReport[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Monthly Sale
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {monthlySaleReport[0]
              ? monthlySaleReport.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.MONTH_NAME}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹ {FormatNumber2(d.NET_AMT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

      <div>
        <div
          style={{ fontSize: 16 }}
          className="flex text-lg text-brown font-bold mt-6"
        >
          <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
            Today Front Office Status
          </div>
        </div>
        <div className="grid d:grid-cols-8  l:grid-cols-5 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
          <div className="flex">
            <div
              style={{
                boxShadow: "1px 1px 1px gray",
              }}
              className="flex d:flex w-60 h-16 bg-[#E8E2DB] border rounded-md  hover:cursor-pointer  hover:z-40"
            >
              <div className=" p-2 w-full ">
                <div className="  flex justify-between items-center ">
                  <div className="grow ">
                    <div className="text-[#4a4a4a] font-medium flex grow ">
                      Expected Arrivals
                    </div>
                    <div className="text-[#4a4a4a] font-medium flex grow ">
                      {expectedArrivals.results}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div
              style={{
                boxShadow: "1px 1px 1px gray",
              }}
              className="flex d:flex w-60 h-16 bg-[#E8E2DB] border rounded-md  hover:cursor-pointer  hover:z-40"
            >
              <div className=" p-2 w-full ">
                <div className="  flex justify-between items-center ">
                  <div className="grow ">
                    <div className="text-[#4a4a4a] font-medium flex grow whitespace-nowrap">
                      Expected Departure
                    </div>
                    <div className="text-[#4a4a4a] font-medium flex grow ">
                      {expectedDeparture.results}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div
              style={{
                boxShadow: "1px 1px 1px gray",
              }}
              className="flex d:flex w-60 h-16 bg-[#E8E2DB] border rounded-md  hover:cursor-pointer  hover:z-40"
            >
              <div className=" p-2 w-full ">
                <div className="  flex justify-between items-center ">
                  <div className="grow ">
                    <div className="text-[#4a4a4a] font-medium flex grow ">
                      Today Checkout
                    </div>
                    <div className="text-[#4a4a4a] font-medium flex grow ">
                      {todayCheckOut.results}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {totalRooms[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Total Rooms
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-5 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {totalRooms[0]
              ? totalRooms.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] text-md font-medium flex grow ">
                              {d.room_status}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.ROOM_COUNT}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SaleDeskRooms;

import React, { useEffect, useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
  setHourlySaleStatus,
} from "../../../utility/Redux/refresh";
import { selectPrefix } from "../../../utility/Redux/profile";

import { setloading } from "../../../utility/Redux/modal";

import { useNavigate } from "react-router-dom";
import { Card } from "../../../utility/component/card";
import { selectheader } from "../../../utility/Redux/security";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

const SectionWiseSale = ({ prefix }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bgList, setBgList] = useState([]);

  const [SectionWiseData, setSectionWiseData] = useState([]);

  // const ResturantStatus = useSelector(SelectResturantStatus)
  const PaymodeStatus = useSelector(SelectPaymodeStatus);

  const checkDateData = useSelector(SelectDate);

  const headers = useSelector(selectheader);

  function generateRandomColors(count) {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.6)`;
      colors.push(color);
    }
    return colors;
  }
  useEffect(() => {
    if (SectionWiseData[0]) {
      setBgList(generateRandomColors(SectionWiseData.length));
    }
  }, [SectionWiseData]);
  const getSectionWiseReport = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/sectionWiseSaleReport`,
        {
          table_prefix: prefix,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }

        setSectionWiseData(rowList);
        dispatch(setHourlySaleStatus());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getSectionWiseReport();
  }, [checkDateData, PaymodeStatus, prefix]);
  const data = {
    labels: SectionWiseData.map((o) => o.SNAME),
    datasets: [
      {
        label: "Sale",
        data: SectionWiseData.map((o) => o.NET_AMT),
        backgroundColor: bgList,
        hoverBackgroundColor: bgList,
      },
    ],
  };

  const width = window.innerWidth;

  return (
    <div>
      {SectionWiseData[0] ? (
        <div className="pb-4 ">
          <div
            style={{ fontSize: 16 }}
            className="flex  pl-1 mb-1 text-brown  text-lg font-bold"
          >
            Section Wise Sale
          </div>

          <div className="t:flex">
            <div className="grid h-fit max-sm:grid-cols-2  grid-cols-2 tab:grid-cols-2 t:grid-cols-2 d:grid-cols-2 gap-2 mt-2">
              {SectionWiseData[0]
                ? SectionWiseData.map((d, index) => {
                    return (
                      <>
                        {!d.NET_AMT == 0 ? (
                          <div
                            key={index}
                            style={{
                              boxShadow: "1px 1px 1px gray",
                            }}
                            className=" rounded-lg overflow-hidden flex "
                            onClick={() => {
                              navigate("/date_wise_sale", {
                                state: {
                                  table_prefix: prefix,
                                  month_name: "",
                                  itm_group: "",
                                  outlet_name: "",
                                  sname: d.SNAME,
                                  from_date: checkDateData,
                                  to_date: checkDateData,
                                  type: d.SNAME,
                                  list: SectionWiseData,
                                  code: "SNAME",
                                  label: "Section",
                                },
                              });
                            }}
                          >
                            {!d.NET_AMT == 0 ? (
                              <Card
                                key={index}
                                t={d.SNAME}
                                amount={d.NET_AMT}
                                color={bgList[index]}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </>
                    );
                  })
                : null}
            </div>

            <div
              style={{
                height: width < 500 ? "300px" : "400px",
                width: width < 500 ? "300px" : "400px",
                marginTop: width < 500 ? "20px" : "0px",
                marginBottom: "20px",
              }}
            >
              <Pie data={data} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SectionWiseSale;

import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { FormatNumber2 } from "../../../utility/component/tools";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { setloading } from "../../../utility/Redux/modal";
import { selectPrefix } from "../../../utility/Redux/profile";
import { selectheader } from "../../../utility/Redux/security";

const MonthlySale = ({ prefix }) => {
  console.log(prefix);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const [monthlyReport, SetMonthlyReport] = useState([]);

  const headers = useSelector(selectheader);
  // const from = useSelector(selectcur)
  const getMonthlyReport = () => {
    dispatch(setloading(true));

    axios
      .post(
        `/api/salesReports/monthlySaleReport`,
        {
          table_prefix: prefix,
          // from_date: from,
          // to_date: to,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        SetMonthlyReport(data);
        var t = 0;
        for (let i = 0; i < data.length; i++) {
          t = t + data[i].NET_AMT;
        }
        setTotal(t);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getMonthlyReport();
  }, [prefix]);

  // setTotal(t)

  return (
    <div className="ml-1 pb-4   ">
      <div
        style={{ fontSize: 16 }}
        className="flex text-lg text-brown font-bold"
      >
        Monthly Sale(s)
      </div>
      <div className="grid d:grid-cols-8 pl-1 l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 ">
        {monthlyReport[0]
          ? monthlyReport.map((d, index) => {
              return (
                <div
                  style={{
                    boxShadow: "1px 1px 1px gray",
                  }}
                  key={index}
                  className="flex d:flex bg-[#b1b1b5]  rounded-lg text-[#404042]    hover:cursor-pointer  hover:z-40 hover:bg-slate-200"
                  onClick={() => {
                    navigate("/date_wise_sale", {
                      state: {
                        table_prefix: prefix,
                        month_name: d.MONTH_NAME,
                        itm_group: "",
                        outlet_name: "",
                        sname: "",
                        from_date: moment()
                          .month(index)
                          .year(moment().format("YYYY"))
                          .startOf("month")
                          .format("YYYY-MM-DD"),
                        to_date: moment()
                          .month(index)
                          .year(moment().format("YYYY"))
                          .endOf("month")
                          .format("YYYY-MM-DD"),
                        type: d.MONTH_NAME,
                        list: monthlyReport,
                        code: "MONTH_NAME",
                        label: "Month",
                      },
                    });
                  }}
                >
                  <div className=" p-2">
                    <span className="  font-medium">{d.MONTH_NAME}</span>
                    <div
                      style={{ whiteSpace: "nowrap" }}
                      className="text-black  font-normal text-base pl-1 flex  "
                    >
                      ₹ {FormatNumber2(d.NET_AMT)}
                      /-
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        <div className=" p-2 bg-[#383535] rounded-lg  shadow-lg">
          <span className=" font-medium text-green">Total</span>
          <div
            style={{ whiteSpace: "nowrap" }}
            className=" font-normal text-base pl-1 flex  text-green "
          >
            ₹ {FormatNumber2(total)}
            /-
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlySale;
